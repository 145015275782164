// React
import React from 'react';

// Bootstrap
import NavBar from 'react-bootstrap/NavBar';
import Button from 'react-bootstrap/Button';

// CSS
import '../css/Navigation.css';

// Import images
import upToUsLogo from '../images/up_to_us_logo.svg';
import intrvlNameLogo from '../images/intrvl_name_logo.svg';
import intrvlLineLogo from '../images/intrvl_line_logo.svg';

// Logos to the left and donate to the right
function Navigation() {

    // Return the navigation
    return (
        <NavBar 
            className="navbar-dynamic"
            expand="md" 
            fixed="top"
        >

            {/* Logo which includes company name */}
            <NavBar.Brand
                className='d-flex align-items-center w-100'
            >   
                <div className='navbar-logo-width d-flex align-items-center h-100'>
                    <a href='https://www.uptous.org/home' target="_blank" rel="noreferrer">
                        <img 
                            src={ upToUsLogo } 
                            alt='Up to Us Logo'
                            className='navbar-up-to-us'
                        />
                    </a>
                    <div className="sep-line">&nbsp;</div>
                    <a href='https://www.intrvl.us/' target="_blank" rel="noreferrer">
                        <img 
                            src={ intrvlLineLogo } 
                            alt='Intrvl Line Logo'
                            className='navbar-intrvl-line'
                        />
                        <img 
                            src={ intrvlNameLogo } 
                            alt='Intrvl Name Logo'
                            className='navbar-intrvl-name'
                        />
                    </a>
                </div>

                {/* Donate and INTRVL logo */}
                <span className='d-none d-md-block ml-auto'>
                    <a href='https://www.uptous.org/donate' target="_blank" rel="noreferrer">
                        <Button
                            className='donate-btn'
                        >
                            Donate
                        </Button>
                    </a>
                </span>
            </NavBar.Brand>

        {/* Close out the navbar */}
        </NavBar>
    );}

// Export the nav for import
export default Navigation;