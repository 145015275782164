// React
import React from 'react';

// CSV downloading
import { CSVLink } from "react-csv";

// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

// CSS
import '../css/Share.css';

// Download and share buttons
function Share(props) {
    const hesitant = props.hesitant;
    const showPop = props.showPop;

    // Convert the data into a downloadable format
    var downloadData = [];
    if (hesitant) {
        var length = hesitant.hesitant_percent.length;
        for (let i = 0; i < length; i++) {
            var row = {};
            for (var k in hesitant) {
                row[k] = hesitant[k][i];
            };
            downloadData.push(row);
        };
    };
    
    // Share image
    var shareImg = (
        <svg width="100%" height="100%" viewBox="0 0 31 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id='share-pic' d="M10.8028 12.9091C11.3551 12.9091 11.8028 12.4614 11.8028 11.9091C11.8028 11.3568 11.3551 10.9091 10.8028 10.9091V12.9091ZM19.7887 10.9091C19.2364 10.9091 18.7887 11.3568 18.7887 11.9091C18.7887 12.4614 19.2364 12.9091 19.7887 12.9091V10.9091ZM16.0029 0.292893C15.6124 -0.0976311 14.9792 -0.0976311 14.5887 0.292893L8.22471 6.65685C7.83418 7.04738 7.83418 7.68054 8.22471 8.07107C8.61523 8.46159 9.2484 8.46159 9.63892 8.07107L15.2958 2.41421L20.9526 8.07107C21.3432 8.46159 21.9763 8.46159 22.3668 8.07107C22.7574 7.68054 22.7574 7.04738 22.3668 6.65685L16.0029 0.292893ZM10.8028 10.9091H3V12.9091H10.8028V10.9091ZM0 13.9091V39H2V13.9091H0ZM3 42H28V40H3V42ZM31 39V13.9091H29V39H31ZM28 10.9091H19.7887V12.9091H28V10.9091ZM16.2958 26.0505V1H14.2958V26.0505H16.2958ZM31 13.9091C31 12.2522 29.6569 10.9091 28 10.9091V12.9091C28.5523 12.9091 29 13.3568 29 13.9091H31ZM28 42C29.6569 42 31 40.6569 31 39H29C29 39.5523 28.5523 40 28 40V42ZM0 39C0 40.6568 1.34314 42 3 42V40C2.44772 40 2 39.5523 2 39H0ZM3 10.9091C1.34314 10.9091 0 12.2522 0 13.9091H2C2 13.3568 2.44771 12.9091 3 12.9091V10.9091Z" fill="black"/>
        </svg>
    );

    // Download image
    var downloadImg = (
        <svg width="100%" height="100%" viewBox="0 0 31 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id='download-pic' d="M20.1972 29.0909C19.6449 29.0909 19.1972 29.5386 19.1972 30.0909C19.1972 30.6432 19.6449 31.0909 20.1972 31.0909L20.1972 29.0909ZM11.2113 31.0909C11.7636 31.0909 12.2113 30.6432 12.2113 30.0909C12.2113 29.5386 11.7636 29.0909 11.2113 29.0909L11.2113 31.0909ZM14.9971 41.7071C15.3876 42.0976 16.0208 42.0976 16.4113 41.7071L22.7753 35.3431C23.1658 34.9526 23.1658 34.3195 22.7753 33.9289C22.3848 33.5384 21.7516 33.5384 21.3611 33.9289L15.7042 39.5858L10.0474 33.9289C9.65685 33.5384 9.02368 33.5384 8.63316 33.9289C8.24263 34.3195 8.24263 34.9526 8.63316 35.3431L14.9971 41.7071ZM20.1972 31.0909L28 31.0909L28 29.0909L20.1972 29.0909L20.1972 31.0909ZM31 28.0909L31 3L29 3L29 28.0909L31 28.0909ZM28 -1.74846e-07L3 -2.36041e-06L3 2L28 2L28 -1.74846e-07ZM3.32207e-06 3L1.12855e-06 28.0909L2 28.0909L2 3L3.32207e-06 3ZM3 31.0909L11.2113 31.0909L11.2113 29.0909L3 29.0909L3 31.0909ZM14.7042 15.9495L14.7042 41L16.7042 41L16.7042 15.9495L14.7042 15.9495ZM1.12855e-06 28.0909C9.83702e-07 29.7478 1.34315 31.0909 3 31.0909L3 29.0909C2.44772 29.0909 2 28.6432 2 28.0909L1.12855e-06 28.0909ZM3 -2.36041e-06C1.34315 -2.50526e-06 3.46691e-06 1.34314 3.32207e-06 3L2 3C2 2.44771 2.44772 2 3 2L3 -2.36041e-06ZM31 3C31 1.34315 29.6569 -2.99986e-08 28 -1.74846e-07L28 2C28.5523 2 29 2.44772 29 3L31 3ZM28 31.0909C29.6569 31.0909 31 29.7478 31 28.0909L29 28.0909C29 28.6432 28.5523 29.0909 28 29.0909L28 31.0909Z" fill="black"/>
        </svg>
    );

    // Render the buttons
    return (
        <div>
            <Row className='mt-0 mt-md-4 d-none d-md-flex'>
                <Col md={ 6 } className='mt-0 mt-md-3 text-center text-md-right'>
                    <Button 
                        className='share-btn orange-btn'
                        onClick={ (e) => showPop(true) }
                    >
                    <div className='d-flex flex-direction-row align-items-center justify-content-center'>
                            <div className='share-icon'>{ shareImg }</div>
                            <p className='mb-0 text-left'>
                                Share<br />Data
                            </p>
                        </div>
                    </Button>
                    <a href='https://www.uptous.org/donate' target="_blank" rel="noreferrer" className='blue-btn-no-hover'>
                        <Button 
                            className='share-btn blue-btn d-block d-md-none mt-2'
                        >
                        <div className='d-flex flex-direction-row align-items-center justify-content-center'>
                                <p className='mt-1 mb-0 text-center'>
                                    Contribute To<br />This Project
                                </p>
                            </div>
                        </Button>
                    </a>
                </Col>
                <Col md={ 6 } className='mt-3 text-center'>
                    <CSVLink
                        data={ downloadData }
                        filename={"vaccine_hesitancy_up_to_us_intrvl.csv"}
                        className="btn share-btn grey-btn d-flex flex-direction-row align-items-center justify-content-center"
                        target="_blank"
                    >
                        <div className='share-icon'>{ downloadImg }</div>
                        <p className='mb-0 text-left'>
                            Download<br />Data
                        </p>
                    </CSVLink>
                </Col>
            </Row>
            <Row className='mt-0 mt-md-4 d-flex d-md-none'>
                <Col className='mt-0 mt-md-3 text-center text-md-right col-6 col-md-12'>
                    <Button 
                        className='share-btn orange-btn'
                        onClick={ (e) => showPop(true) }
                    >
                    <div className='d-flex flex-direction-row align-items-center justify-content-center'>
                            <div className='share-icon'>{ shareImg }</div>
                            <p className='mb-0 text-left'>
                                Share
                            </p>
                        </div>
                    </Button>
                </Col>
                <Col className='mt-0 mt-md-3 text-center text-md-right col-6 col-md-12'>
                    <a href='https://www.uptous.org/donate' target="_blank" rel="noreferrer" className='blue-btn-no-hover'>
                        <Button 
                            className='share-btn blue-btn d-block d-md-none'
                        >
                        <div className='d-flex flex-direction-row align-items-center justify-content-center'>
                                <p className='mt-1 mb-0 text-center'>
                                    Contribute
                                </p>
                            </div>
                        </Button>
                    </a>
                </Col>
            </Row>
        </div>
    );
}

// Export share for imports
export default Share;