// React
import React from 'react';

// CSS
import '../css/Filters.css';

// Components
import Common from './Common.js';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

// Filters are passed between the page and the component
function Filters(props) {
    const changeFilter = props.changeFilter;
    const filters = props.filters;

    // Create the state options
    const stateAbbreviations = Common.getStateArray();
    const stateOptions = [<option value='all' key={ 51 }>All States</option>];
    var i;
    for (i = 0; i < stateAbbreviations.length; i++) {
        if (stateAbbreviations[i] !== 'AK') {

            // Select the filter state
            if (stateAbbreviations[i] === filters.state) {
                stateOptions.push(
                    <option key={ i } selected>{ stateAbbreviations[i] }</option>
                );
            } else {
                stateOptions.push(
                    <option key={ i }>{ stateAbbreviations[i] }</option>
                );
            };
        };
    };

    // Return the filters in two rows
    return (
        <div className='mb-1 mb-md-4'>
            <Row>

                {/* State */}
                <Col className='col-6 col-md-3'>
                    <Form.Group controlId="exampleForm.ControlSelectState" className='mb-4'>
                        <Form.Control 
                            as="select"
                            name='state'
                            className='filters-form-control silka-light'
                            onChange={ (event) => changeFilter(event) }
                        >
                            { stateOptions }
                        </Form.Control>
                    </Form.Group> 
                </Col>

                {/* Age */}
                <Col className='col-6 col-md-3'>
                    <Form.Group controlId="exampleForm.ControlSelectAge" className='mb-4'>
                        <Form.Control 
                            as="select"
                            name='age_groups'
                            className='filters-form-control silka-light'
                            onChange={ (event) => changeFilter(event) }
                        >
                            <option value='all'>All Ages</option>
                            <option>18-24</option>
                            <option>25-34</option>
                            <option>35-44</option>
                            <option>45-54</option>
                            <option>55-64</option>
                            <option>65+</option>
                        </Form.Control>
                    </Form.Group>
                </Col>

                {/* Race */}
                <Col className='col-6 col-md-3'>
                    <Form.Group controlId="exampleForm.ControlSelectRace" className='mb-4'>
                        <Form.Control 
                            as="select"
                            name='race'
                            className='filters-form-control silka-light'
                            onChange={ (event) => changeFilter(event) }
                        >
                            <option value='all'>All Races</option>
                            <option value='asian'>Asian</option> 
                            <option value='black'>Black</option> 
                            <option value='hispanic'>Hispanic</option> 
                            <option value='other'>Other</option> 
                            <option value='white'>White</option> 
                        </Form.Control>
                    </Form.Group>
                </Col>

                {/* Gender */}
                <Col className='col-6 col-md-3'>
                    <Form.Group controlId="exampleForm.ControlSelectSex" className='mb-0'>
                        <Form.Control 
                            as="select"
                            name='gender'
                            className='filters-form-control silka-light'
                            onChange={ (event) => changeFilter(event) }
                        >
                            <option value='all'>All Genders</option>
                            <option value='female'>Female</option>
                            <option value='male'>Male</option>
                        </Form.Control>
                    </Form.Group>
                </Col>

            {/* Close out the row */}
            </Row>

            {/* Return to national */}
            { 
                (filters.state !== 'all') 
                ? (
                    <p
                        className='text-center text-md-left hover-text mt-4 mt-md-0'
                        onClick={ () => changeFilter({
                            target: {
                                name: 'state',
                                value: 'all'
                            }
                        }) }
                    >
                        <b>Return to National Map</b> <FontAwesomeIcon icon={ faArrowRight } />
                    </p>
                ) : <div /> 
            }
        </div>
    )
}

// Export the filters for imports
export default Filters;