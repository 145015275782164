// React
import React from 'react';

// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

// CSS
import '../css/SignUp.css';

// Email sign up
function SignUp(props) {
    const email = props.email;
    const changeEmail = props.changeEmail;

    // Determine if we should show the thank you message
    if (!email) {
        var form = <Form onSubmit={ changeEmail } className='email-form'>
            <InputGroup>
                <FormControl
                    placeholder="Email"
                    aria-label="Email"
                    aria-describedby="basic-addon2"
                    className='email-submit'
                    id='email'
                />
                <InputGroup.Append>
                    <Button 
                        variant="outline-secondary"
                        className='email-btn'
                        type='submit'
                    >
                        Submit
                    </Button>
                </InputGroup.Append>
            </InputGroup>
        </Form>
    } else {
        form = <h2>Thank You!</h2>
    }

    // Render the buttons
    return (
        <Row className='mt-4'>
            <Col md={ 12 } className='d-flex align-items-center justify-content-center mt-3'>
                <div className='email-box text-center d-flex align-items-center justify-content-center'>
                    <h2 className='page-title mb-5'>
                        Sign Up for National Vaccination Updates
                    </h2>
                    { form }
                </div>
            </Col>
        </Row>
    );
}

// Export share for imports
export default SignUp;