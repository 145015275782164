// React
import React from 'react';

// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// CSS
import '../css/Legend.css';

// Images
import LegendLine from '../images/legend.svg';

// Legend for the map
function Legend() {

    // Render the buttons
    return (
        <Row className='mb-5 mb-md-4 mt-5 legend-size'>
            <Col md={ 12 } className='d-flex justify-content-center align-items-center text-center'>
                <p className='mb-0 mr-3 legend-text'><b>Fewer Vaccinations</b></p>
                <img src={ LegendLine } className='legend-bar' alt='Legend'></img>
                <p className='mb-0 ml-3 legend-text'><b>More Vaccinations</b></p>
            </Col>
        </Row>
    );
}

// Export legend for imports
export default Legend;