// React
import React from 'react';

// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// CSS
import '../css/Footer.css';

// Import images
import upToUsLogo from '../images/up_to_us_logo.svg';
import intrvlNameLogo from '../images/intrvl_name_logo.svg';
import intrvlLineLogo from '../images/intrvl_line_logo.svg';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

// Use the background image for the full footer
function Footer() {

    // Return the JSX for the homepage
    return (
        <div className='footer p-5'>
            <Row className='align-items-center row-padding'>
                <Col md={ 12 } className='footer-logo-column mb-4 text-center'>
                    <img 
                        src={ upToUsLogo } 
                        alt='Up to Us Logo'
                        className='footer-up-to-us'
                    />
                    <img 
                        src={ intrvlLineLogo } 
                        alt='Intrvl Line Logo'
                        className='ml-4 ml-md-5 footer-intrvl-line'
                    />
                    <img 
                        src={ intrvlNameLogo } 
                        alt='Intrvl Name Logo'
                        className='footer-intrvl-name'
                    />
                </Col>
            </Row>
            <Row className='text-center align-items-center row-padding'>
                <Col md={ 12 }>
                    <p className='footer-text-bold mb-4'>
                        © 2021 Up to Us & INTRVL&#09;|&#09;<a href="https://www.uptous.org/privacy">Privacy Policy</a>
                    </p>
                    <p className='footer-text-normal mb-4'>
                        Vaccination, hesitancy, and resistance estimates were obtained
                        through a multilevel regression and poststratification
                        of recent survey data. To learn more, visit our <a href="/methodology">methodology</a> page.
                    </p>
                    <p className='footer-text-normal no-underline mb-0'>
                        <a href='https://www.uptous.org/home' target="_blank" rel="noreferrer">
                            Continue to uptous.org <FontAwesomeIcon icon={ faArrowRight } />
                        </a>
                    </p>
                </Col>
            </Row>
        </div>
    )
}

// Export home for imports
export default Footer;
