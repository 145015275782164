// React
import React from 'react';
import {
  Switch,
  Route
} from 'react-router-dom';

// Import CSS for the application
import './App.css';

// Import components
import Home from './jsx/Home.js';
import Frame from './jsx/Frame.js';
import Methodology from './jsx/Methodology.js';

// Route to the components
function App() {
  return (
    <main>
      <Switch>
        <Route path='/' component={ Home } exact />
        <Route path='/frame' component={ Frame } exact />
        <Route path='/methodology' component={ Methodology } exact />
      </Switch>
    </main>
  );
}

// Export the application for import in index
export default App;
