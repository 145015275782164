// React
import React from 'react'

// Plotly
import Plot from 'react-plotly.js';

// Components
import Loading from './Loading.js';

// Resizing
import useResizeObserver from 'use-resize-observer';

// Receive the data and geojson from home
function Choropleth(props) {
    const data = props.data;
    const geojson = props.geojson;
    const location = props.state;
    const changeClick = props.changeClick;
    const clickData = props.clickData;
    const toggleData = props.toggleData;

    // Get the resizing info
    const { ref, width = 1, height = 1 } = useResizeObserver();

    // Show a loading message if the data or geojson not available
    if ((!data) || (!geojson)) {
        return (
            <Loading />
        );
    };

    // Use the name we provided in the data
    var text = '%{text}<br>Resistant & Hesitant: %{z:.0%}<extra></extra>';

    // Construct the geo object
    const geoObj = {
        scope: 'usa',
        countrycolor: 'rgba(0, 0, 0, 0)',
        showland: true,
        landcolor: 'rgba(0, 0, 0, 0)',
        showlakes: true,
        lakecolor: 'rgba(0, 0, 0, 0)',
        subunitcolor: 'rgba(0, 0, 0, 0)',
        bgcolor: 'rgba(0, 0, 0, 0)'
    };

    // Make sure the bounds are correct for the selection
    const fips = data.geo.map(x => x.substring(0, 2));
    const fipsUnique = [...new Set(fips)];
    if ((location) && (fipsUnique.length === 1)) {
        geoObj.fitbounds = 'locations'
    };

    // Append values to the data
    data.hesitant_percent.push(0.0);
    data.hesitant_percent.push(1);

    // Use herd immunity for the threshold
    var colorscale = [
        [0.0, 'rgba(0, 0, 0, 0.15)'],  // Start with white for 0
        [0.001, '#5BAF9C'],  // Begin herd immunity
        [0.3, '#5BAF9C'],  // End herd immunity
        [0.31, '#80C2DC'], 
        [0.45, '#E69538'],  // Danger zone
        [1, '#BD7932']  // No one is vaccinated
    ];

    // Construct the data for the map
    var mapData = [
        {
            type: 'choropleth',
            geojson: geojson,
            autocolorscale: false,
            locations: data.geo,
            text: data.geo_name,
            hovertemplate: text,
            hoverlabel: {
                font: {
                    family: "SilkaMono-Regular"
                },
                bgcolor: 'black'
            },
            z: data.hesitant_percent,
            colorscale: colorscale,
            showscale: false,
            marker: {
                line: { 
                    color : 'rgba(0, 0, 0, 0.75)',
                    width: 0.25
                }
            }
        }
    ]

    // If a specific county is selected, overlay that county
    if (toggleData) {
        mapData.push({
            type: 'choropleth',
            geojson: geojson,
            autocolorscale: false,
            locations: [clickData.fipsState + clickData.fipsCounty],
            text: data.geo_name,
            hoverinfo: 'none',
            z: data.hesitant_percent,
            colorscale: [
                [0, 'rgba(0, 0, 0, 0)'],
                [1, 'rgba(0, 0, 0, 0)'],
            ],
            showscale: false,
            marker: {
                line: { 
                    color : 'rgba(255, 255, 255, 1)',
                    width: 1
                }
            }
        });
    };

    // Return the map fit to the screen
    return (
        <div style={ { width: '100%', height: '100%'} } ref={ ref }>

            {/* Configuration should include mapbox token */}
            <Plot
                config={
                    {
                        displayModeBar: false,
                        mapboxAccessToken: 'pk.eyJ1Ijoiam9ld2xvczE3IiwiYSI6ImNqejA3OXFrZDAyZ3kzZW5oOGI3MmpiZnAifQ.N66VC_PfNMGjuzdAPbOGkA'
                    }
                }

                // Set the data with our scale
                data={ mapData }

                // Make sure the layout has no margin
                layout={ 
                    {
                        width: width, 
                        height: height,
                        dragmode: false,
                        paper_bgcolor: 'rgba(0, 0, 0, 0)',
                        geo: geoObj,
                        margin: {
                            l: 0,
                            r: 0,
                            t: 0,
                            b: 0
                        }
                    }
                }

                // Click event for changing the zoom
                onClick={
                    data => changeClick(data)
                }
            />
        
        {/* Close out the component */}
        </div>
    )
}

// Export choropleth for imports
export default Choropleth;


