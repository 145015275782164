// React
import React from 'react';

// Bootstrap
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

// CSS
import '../css/Toggle.css';

// Need props for displaying specific data in statistics
function Toggle(props) {
    const toggleSelect = props.toggle;
    const changeToggle = props.changeToggle;
    const filter = props.filter;
    const click = props.click;

    // We don't want to show the toggle on initial load
    var hidden = false;
    if ((!click.nameCounty) || (filter === 'all') || (filter !== click.abbrState)) {
        hidden = true;
    };

    // Toggle between county and larger geo view
    return (
        <div>
            <div className={ (hidden) ? 'd-none' : 'mt-4 mb-4' }>
                <ButtonGroup toggle>
                    <ToggleButton
                        type="radio"
                        variant="secondary"
                        name="radio"
                        value="higher"
                        checked={ (toggleSelect === 0) ? true : false }
                        onChange={ (e) => changeToggle(0) }
                    >
                        { (filter !== 'all') ? 'State' : 'Country' }
                    </ToggleButton>
                    <ToggleButton
                        type="radio"
                        variant="secondary"
                        name="radio"
                        value="county"
                        checked={ (toggleSelect === 1) ? true : false }
                        onChange={ (e) => changeToggle(1) }
                    >
                        County
                    </ToggleButton>
                </ButtonGroup>
            </div>

            {/* Show instructions for county */}
            { (toggleSelect === 1) ?
                <p className='subtitle-text mt-2 mb-2'>
                    <i>Click the map to select another county.                        
                        <span className='d-block d-md-none'>
                            "Vaccinated" includes people who are partially vaccinated.
                        </span>
                    </i>
                </p>
                : <div />
            }

            {/* Empty instructions for higher but correct county */}
            { ((toggleSelect === 0) && (!hidden) && (filter !== 'all')) ?
                <p className='subtitle-text mt-2 mb-2'>
                    <i>Displaying statistics for the whole state.                        
                        <span className='d-block d-md-none'>
                            "Vaccinated" includes people who are partially vaccinated.
                        </span>
                    </i>
                </p>
                : <div />
            }

            {/* Show instructions for higher */}
            { ((toggleSelect === 0) && (hidden) && (filter !== 'all')) ?
                <p className='subtitle-text mt-2 mb-2'>
                    <i>Click the map to select a county.                        
                        <span className='d-block d-md-none'>
                            "Vaccinated" includes people who are partially vaccinated.
                        </span>
                    </i>
                </p>
                : <div />
            }

            {/* Show instructions for national */}
            { (filter === 'all') ?
                <p className='subtitle-text mt-2 mb-2'>
                    <i>Click the map to select a state or county.                        
                        <span className='d-block d-md-none'>
                            "Vaccinated" includes people who are partially vaccinated.
                        </span>
                    </i>
                </p>
                : <div />
            }
        </div>
    );
}

// Export toggle for imports
export default Toggle;