// React
import React, {
    useState,
    useEffect
} from 'react';

// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

// Components
import Common from './Common.js';

// Components
import Loading from './Loading.js';

// Images
import infographicMap from '../images/infographic.svg';
import infographicPostMap from '../images/infographic_map.svg';
import infographicPostNeedle from '../images/infographic_needle.svg';

// Edit SVG
import { SvgLoader, SvgProxy } from "react-svgmt";

// CSS
import '../css/PopUp.css';

// Require the SVG to PNG package
const saveSvgAsPng = require('save-svg-as-png');

// Fill the screen with our shareable images
function PopUp(props) {
    var hesitant = props.hesitant;
    const showPop = props.showPop;
    const filters = props.filters;
    const toggle = props.toggle;
    const click = props.click;

    // Use the URI as a state, so the image doesn't load until the map is ready
    const [mapURI, setMapURI] = useState(false);
    const [infographicURI, setInfographicURI] = useState(false);
    const [infographicMapURI, setInfographicMapURI] = useState(false);
    const [infographicNeedleURI, setInfographicNeedleURI] = useState(false);

    // If the toggle is county and we have a click, switch the geo
    var filtersCopy = JSON.parse(JSON.stringify(filters));
    if ((toggle === 1) && (click.nameCounty)) {
        if (click.abbrState === 'LA') {
            filtersCopy.state = click.nameCounty + ' Parish, ' + click.abbrState;
        } else {
            filtersCopy.state = click.nameCounty + ' County, ' + click.abbrState;
        };
    };

    // Only get the variables if data is available
    var perc = 0;
    var x2 = 540;
    var y2 = 1680;
    var y2Post = 775;
    if (hesitant) {

        // If the toggle is county and we have the click, get the index of the county
        if ((toggle === 1) && (click.nameCounty)) {
            var search = click.fipsState + click.fipsCounty;
            var position = hesitant.geo.indexOf(search);

            // Select only that county
            hesitant = {
                population: [hesitant.population[position]],
                low_hesitant_population: [hesitant.low_hesitant_population[position]],
                med_hesitant_population: [hesitant.med_hesitant_population[position]],
                high_hesitant_population: [hesitant.high_hesitant_population[position]],
            };
        };

        // Calculate how many people we need to reach herd immunity threshold
        var totalPop = hesitant.population.reduce((a, b) => a + b, 0);
        var hesitantPop = hesitant.med_hesitant_population.reduce((a, b) => a + b, 0);
        var hesitantPerc = hesitantPop / totalPop;
        var resistantPop = hesitant.high_hesitant_population.reduce((a, b) => a + b, 0);
        var resistantPerc = resistantPop / totalPop;
        var readyPop = hesitant.low_hesitant_population.reduce((a, b) => a + b, 0);
        var readyPerc = readyPop / totalPop;
        perc = Math.round((1 - hesitantPerc - resistantPerc - readyPerc) * 100);

        // Calculate the angle
        var anglePerc = ((1 - hesitantPerc - resistantPerc - readyPerc) - 0.5) * 2;
        var angle = (90 * anglePerc) * (Math.PI / 180);
        x2 = (540 + (250 * Math.sin(angle))).toString();
        y2 = (1680 - (250 * Math.cos(angle))).toString();
        y2Post = (775 - (250 * Math.cos(angle))).toString();
    };

    // Upon load, get the URI for the map
    useEffect(() => {
        var svg = document.getElementsByClassName('main-svg');
        
        // Only set when the URI when the result is actually available
        if ((svg) && (svg.length > 0)) {
            saveSvgAsPng.svgAsPngUri(
                svg[0], 
                Common.imageOptions()
            ).then(uri => 
                setMapURI(uri)
            );
        };
    });

    // Once we have the map rendered, make the infographic
    useEffect(() => {
        var svg = document.getElementById('map-infographic');
        if (svg) {
            saveSvgAsPng.svgAsPngUri(
                svg, 
                Common.imageOptions()
            ).then(uri => 
                setInfographicURI(uri)
            );
        };
        var svgMap = document.getElementById('map-post-infographic');
        if (svgMap) {
            saveSvgAsPng.svgAsPngUri(
                svgMap, 
                Common.imageOptions()
            ).then(uri => 
                setInfographicMapURI(uri)
            );
        };
        var svgNeedle = document.getElementById('needle-post-infographic');
        if (svgNeedle) {
            saveSvgAsPng.svgAsPngUri(
                svgNeedle, 
                Common.imageOptions()
            ).then(uri => 
                setInfographicNeedleURI(uri)
            );
        };
    });

    // Update the map post SVG if the map has been set
    var infographic2 = 
        <SvgLoader width="25%" height="25%" path={ infographicPostMap }>

            {/* Text */}
            <SvgProxy selector="#text-one">
                { perc + '% of ' + Common.makeDemographicText(filtersCopy, false) }
            </SvgProxy>
            <SvgProxy selector="#text-two">
                {  Common.makeGeoText(filtersCopy) }
            </SvgProxy>

            {/* Images */}
            <SvgProxy 
                selector="#map-image"
                href={ mapURI }
            />
        </SvgLoader>

    // Update the needle post SVG if the map has been set
    var infographic3 = 
        <SvgLoader width="25%" height="25%" path={ infographicPostNeedle }>

            {/* Text */}
            <SvgProxy selector="#text-one">
                { perc + '% of ' + Common.makeDemographicText(filtersCopy, false) }
            </SvgProxy>
            <SvgProxy selector="#text-two">
                {  Common.makeGeoText(filtersCopy) }
            </SvgProxy>

            {/* Needle */}
            <SvgProxy 
                selector="#needle"
                x2={ x2 }
                y2={ y2Post }
            />

            {/* Goal text */}
            <SvgProxy selector="#goal-text">
                { (perc >= 70) ? 'Vaxed' : 'Goal' }
            </SvgProxy>
            <SvgProxy selector="#goal-title">
                { (perc >= 70) ? "It's Up to You to Reach 100%." : "It's Up to You." }
            </SvgProxy>
        </SvgLoader>

    // Update the story SVG if the map has been set
    var infographic = 
        <SvgLoader width="25%" height="25%" path={ infographicMap }>

            {/* Text */}
            <SvgProxy selector="#text-one">
                { perc + '% of ' + Common.makeDemographicText(filtersCopy, false) }
            </SvgProxy>
            <SvgProxy selector="#text-two">
                {  Common.makeGeoText(filtersCopy) }
            </SvgProxy>

            {/* Images */}
            <SvgProxy 
                selector="#map-image"
                href={ mapURI }
            />

            {/* Needle */}
            <SvgProxy 
                selector="#needle"
                x2={ x2 }
                y2={ y2 }
            />

            {/* Goal text */}
            <SvgProxy selector="#goal-text">
                { (perc >= 70) ? 'Vaxed' : 'Goal' }
            </SvgProxy>
            <SvgProxy selector="#goal-title">
                { (perc >= 70) ? "It's Up to You to Reach 100%." : "It's Up to You. Get Vaccinated." }
            </SvgProxy>
        </SvgLoader>

    // Render the infographic
    return (
        <div className='pop-up'>

            {/* Return to dashboard button */}
            <Row className='exit-pop'>
                <Col md={ 12 } className='d-flex align-items-center'>
                    <Button 
                        className='ml-auto mr-auto mr-md-0 return-btn'
                        onClick={ (e) => showPop(false) }
                    >
                        Return to Dashboard
                    </Button>
                </Col>
            </Row>

            {/* Hidden SVG */}
            <div className='d-none'>
                { infographic }
            </div>
            <div className='d-none'>
                { infographic2 }
            </div>
            <div className='d-none'>
                { infographic3 }
            </div>

            {/* Visible PNGs */}
            <div className='img-pop'>

                {/* Posts */}
                <p className='type-desc text-center'>Posts</p>
                <Row className='mb-5 m-0'>
                    <Col md={ 6 } className='d-flex justify-content-center justify-content-md-end align-items-center'>
                        <div className='img-box mb-5 mb-md-0'>
                            { 
                                (infographicMapURI !== false) 
                                ? (
                                    <img
                                        src={ infographicMapURI }
                                        alt='Infographic - Map'
                                        width='100%'
                                        height='100%'
                                    />
                                ) : <Loading />
                            }
                        </div>
                    </Col>
                    <Col md={ 6 } className='d-flex justify-content-center justify-content-md-start align-items-center'>
                        <div className='img-box'>
                            { 
                                (infographicNeedleURI !== false) 
                                ? (
                                    <img
                                        src={ infographicNeedleURI }
                                        alt='Infographic - Needle'
                                        width='100%'
                                        height='100%'
                                    />
                                ) : <Loading />
                            }
                        </div>
                    </Col>
                </Row>

                {/* Story */}
                <p className='type-desc text-center'>Story</p>
                <Row className='mb-5 m-0'>
                    <Col md={ 12 } className='d-flex justify-content-center align-items-center'>
                        <div className='img-box'>
                            { 
                                (infographicURI !== false) 
                                ? (
                                    <img
                                        src={ infographicURI }
                                        alt='Infographic - Story'
                                        width='100%'
                                        height='100%'
                                    />
                                ) : <Loading />
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

// Export pop up for imports
export default PopUp;