// React
import React, {
    useState,
    useEffect
} from 'react';

// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Google Analytics
import ReactGA from "react-ga";

// CSS
import '../css/Home.css';

// Import images
import upToUsLogoBlack from '../images/up_to_us_logo_black.svg';
import intrvlNameLogoBlack from '../images/intrvl_name_logo_black.svg';
import intrvlLineLogoBlack from '../images/intrvl_line_logo_black.svg';

// Import components
import Common from './Common.js';
import Filters from './Filters.js';
import Percentages from './Percentages.js';
import Choropleth from './Choropleth.js';
import Legend from './Legend.js';
import Share from './Share.js';
import Demographics from './Demographics.js';
import Toggle from './Toggle.js';
import Statistics from './Statistics.js';
import PopUp from './PopUp.js';

// Access the data from the API to render the page
function Frame() {

    // Filters determine what we search for in the API
    const [filterData, setFilterData] = useState({
        geo: 'county',  // Not changed right now
        state: 'all',
        age_groups: 'all',
        race: 'all',
        gender: 'all'
    });

    // Immediately load the geojson from the api
    const [geoJSONData, setGeoJSONData] = useState(false);

    // Hesitancy data retrieved from API and initially empty
    const [hesitantData, setHesitantData] = useState(false);

    // Map click event listens to the choropleth and is initially empty
    const [clickData, setClickData] = useState({
        fipsState: false,
        abbrState: false,
        fipsCounty: false,
        nameCounty: false
    });

    // Check which geo level we should use for statistics
    const [toggleData, setToggleData] = useState(0);

    // Current filtering of the map to prevent multiple API calls
    const [previousData, setPreviousData] = useState({
        geo: 'county',  // Not changed right now
        state: false,
        age_groups: false,
        race: false,
        gender: false
    });

    // Show the pop up for the creative statics
    const [sharePop, setSharePop] = useState(false);

    // Methods for initializing analytics
    const initGA = (trackingID) => {           
        ReactGA.initialize(trackingID); 
    };
    const pageGA = () => {  
        ReactGA.pageview(window.location.pathname + window.location.search); 
    };
    const eventGA = (category, action, label) => {
        ReactGA.event({
            category: category,
            action: action,
            label: label
        });
    };

    // Initialize analytics on page load
    useEffect(() => {
        initGA('UA-197573139-2');
        pageGA();
    }, []);

    // Post a message for the iFrame about our height
    useEffect(() => {
        setTimeout(function() {
            let message = {
                height: document.body.scrollHeight, 
                description: 'hesitancy'
            };	
            window.top.postMessage(message, "*");
        }, 1000)
    }, []);

    // Ask the API for the geojson
    useEffect(() => {

        // Fetch the data
        fetch('/api/county_geojson', {  // May not be county if we allow state toggle
            method: 'get',
            headers: {'Content-Type': 'application/json'}
        }).then(res => res.json()).then(data => {

            // Set the geojson data
            setGeoJSONData(data);
        });
    }, []);

    // Main action for filtering
    useEffect(() => {

        // Tell analytics which state we are getting
        eventGA('geo', filterData.state, 'filter');

        // Only fetch new data when necessary
        if (
            (previousData.state !== filterData.state) ||
            (previousData.age_groups !== filterData.age_groups) ||
            (previousData.race !== filterData.race) ||
            (previousData.gender !== filterData.gender)
        ) {

            // Set data to false to trigger loading gif
            setHesitantData(false);

            // Fetch the data
            fetch('/api/hesitancy_data', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(filterData)
            }).then(res => res.json()).then(data => {

                // Set the hesitancy data and change our location
                setHesitantData(data);
                setPreviousData(filterData);
            });
        };
    }, [filterData, previousData]);

    // If there's a click outside the current state, update the filter
    useEffect(() => {
        if (clickData.abbrState) {
            setFilterData(prevState => ({
                ...prevState,
                state: clickData.abbrState
            }));
        };
    }, [clickData]);

    // When we switch to a different state, show the state view before click
    useEffect(() => {
        if (filterData.state !== clickData.abbrState) {
            setToggleData(0);
        };
    }, [filterData, clickData]);

    // If the share button is clicked, show the pop up
    useEffect(() => {
        if (sharePop) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [sharePop]);

    // Change the filter data to send a request to the API
    const changeFilter = (event) => { 
        var name = event.target.name;
        var value = event.target.value;

        // Make sure only county is allowed on state view
        setFilterData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Handle the click, updating the filter so we are only that state
    const changeClick = (data) => {
        var abbr = Common.getStateAbbr(data.points[0].properties.STATE);
        setClickData(prevState => ({
            ...prevState,
            fipsState: data.points[0].properties.STATE,
            abbrState: abbr,
            fipsCounty: data.points[0].properties.COUNTY,
            nameCounty: data.points[0].properties.NAME
        }));

        // Increase the toggle, if we're in the same state
        setToggleData(1);
    };

    // Change the toggle filter, based on user click
    const changeToggle = (data) => {
        setToggleData(data);
    };

    // Switch the share to true
    const showPop = (data) => {
        if (data) {
            eventGA('share', filterData.state, 'click');
        };
        setSharePop(data);
    };

    // Return the JSX for the homepage
    return (
        <div id='whole-page'>

            {/* Page body */}
            <div className='page-frame'>

                {/* Title */}
                <Row className='mb-4'>
                    <Col md={ 12 } className='text-center'>
                        <h1 className='page-title mb-3'>Vaccination Progress & Hesitancy</h1>
                        <p>Last Updated September 17, 2021</p>
                    </Col>
                </Row>
            
                {/* Filters expand into map */}
                <Filters 
                    changeFilter={ changeFilter }
                    filters={ filterData }
                />

                {/* Render the choropleth map within a full size container with legend */}
                <Row>
                    <Col 
                        md={ 3 } 
                        className='d-flex justify-content-center choropleth-title text-center text-md-left mt-4 mt-md-0'
                    >
                        <Demographics 
                            filters={ filterData }
                            toggle={ toggleData }
                            click={ clickData }
                        />
                        <Toggle 
                            toggle={ toggleData }
                            changeToggle={ changeToggle }
                            filter={ filterData.state }
                            click={ clickData }
                        />
                        <Percentages 
                            hesitant={ hesitantData.raw_data }
                            stateReasons={ hesitantData.state_reasons }
                            countyReasons={ hesitantData.county_reasons }
                            toggle={ toggleData }
                            click={ clickData }
                            showPop={ showPop }
                        />
                    </Col>
                    <Col md={ 9 }>
                        <div className='choropleth-size mt-5 mt-md-0'>
                            <Choropleth
                                data={ hesitantData.raw_data }
                                geojson={ geoJSONData }
                                state={ (filterData.state !== 'all') ? true : false }
                                changeClick={ changeClick }
                                clickData={ clickData }
                                toggleData={ toggleData }
                            />
                        </div>
                        <Legend />
                    </Col>
                </Row>

                {/* Hide share here on desktop */}
                <div className='d-block d-md-none'>
                    <Share 
                        hesitant={ hesitantData.raw_data }
                        showPop={ showPop }
                    />
                </div>

                {/* Start the stats section */}
                <div className='stat-section mt-4 mt-md-5'>

                    {/* Statistics */}
                    <Statistics 
                        hesitant={ hesitantData.raw_data }
                        stateReasons={ hesitantData.state_reasons }
                        countyReasons={ hesitantData.county_reasons }
                        toggle={ toggleData }
                        click={ clickData }
                    />

                {/* Close out the stat section */}
                </div>

                {/* Logos */}
                <Row className='align-items-center row-padding'>
                    <Col md={ 12 } className='footer-logo-column mt-4 text-center'>
                        <img 
                            src={ upToUsLogoBlack } 
                            alt='Up to Us Logo'
                            className='footer-up-to-us'
                        />
                        <img 
                            src={ intrvlLineLogoBlack } 
                            alt='Intrvl Line Logo'
                            className='ml-4 ml-md-5 footer-intrvl-line'
                        />
                        <img 
                            src={ intrvlNameLogoBlack } 
                            alt='Intrvl Name Logo'
                            className='footer-intrvl-name'
                        />
                    </Col>
                </Row>

                {/* Methodology */}
                <Row className='mb-0'>
                    <Col md={ 12 } className='mt-4 mb-0 text-center'>
                        <p>
                            Vaccination, hesitancy, and resistance estimates were obtained
                            through a multilevel regression and poststratification
                            of recent survey data. To learn more, visit our <a href="/methodology">methodology</a> page.
                        </p>
                    </Col>
                </Row>
            
            {/* Close out to return */}
            </div>

            {/* Only display popup when share is clicked */}
            { (sharePop) 
                ? 
                    <PopUp
                        hesitant={ hesitantData.raw_data }
                        showPop={ showPop }
                        filters={ filterData }
                        toggle={ toggleData }
                        click={ clickData }
                    /> 
                : [] 
            }
        </div>
    )
}

// Export home for imports
export default Frame;
