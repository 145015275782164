// React
import React from 'react';

// Components
import Common from './Common.js';

// Demographic description determined from filters
function Demographics(props) {
    const filters = props.filters;  // Editable geo name
    const toggle = props.toggle;
    const click = props.click;

    // If the toggle is county and we have a click, switch the geo
    var filtersCopy = JSON.parse(JSON.stringify(filters));
    if ((toggle === 1) && (click.nameCounty)) {
        if (click.abbrState === 'LA') {
            filtersCopy.state = click.nameCounty + ' Parish, ' + click.abbrState;
        } else {
            filtersCopy.state = click.nameCounty + ' County, ' + click.abbrState;
        };
    };

    // Render the title
    return (
        <h3 className='page-title'>
            { Common.makeDemographicText(filtersCopy) }
        </h3>
    );
}

// Export demographics for imports
export default Demographics;