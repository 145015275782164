// React
import React, {
    useState,
    useEffect
} from 'react';

// Bootstrap
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

// CSS
import '../css/ExpandedInsights.css';

// Expanded insights sign up
function ExpandedInsights(props) {

    // Decide whether we should show the form
    const [showForm, setShowForm] = useState(false);
    const [submission, setSubmission] = useState({
        email: false,
        name: false,
        organization: false,
        description: false
    });

    // Whenever submission changes, fire
    useEffect(() => {
        if (submission.email) {
            fetch('/api/inquiries', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(submission)
            })
        };
    }, [submission]);

    // Use the button when it hasn't been clicked
    const button = 
        <div>
            <h2 className='page-title mb-4'>
                Expanded<br />Vaccination<br />Insights
            </h2>
            <p className='mb-4'>
                Contact us<br />to get access<br />to more in-depth<br />data and analysis
            </p>
            <p className='mb-0'>
                <Button 
                    className='inquire-btn'
                    onClick={ (e) => setShowForm(true) }
                >
                    Inquire
                </Button>
            </p>
        </div>;

    // Put the form data in state
    const formSubmit = e => {
        e.preventDefault();
        setSubmission({
            email: e.target.elements.email.value,
            name: e.target.elements.name.value,
            organization: e.target.elements.organization.value,
            description: e.target.elements.description.value
        });
    };

    // The form for accepting email data
    const form = 
        <div className='form-container'>
            <Form onSubmit={ formSubmit }>
                <Form.Group className="mb-3" controlId="email">
                    <Form.Label><b>Email</b></Form.Label>
                    <Form.Control type="email" placeholder="Enter Email" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="name">
                    <Form.Label><b>Name</b></Form.Label>
                    <Form.Control type="text" placeholder="Enter Name" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="organization">
                    <Form.Label><b>Organization</b></Form.Label>
                    <Form.Control type="text" placeholder="Enter Organization Name" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="description">
                    <Form.Label><b>Description</b></Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder='Describe Your Interest' />
                </Form.Group>
                { (!submission.email) ?
                    <Button 
                        variant="primary" 
                        type="submit" 
                        className='donate-btn'
                    >
                        Inquire
                    </Button>
                    : <h2>Thank You!</h2>
                }
            </Form>
        </div>;
    

    // Render the button or form
    return (
        <div className='mt-4 insights-box d-flex justify-content-center align-items-middle text-center'>
            { (showForm) ? form : button }
        </div>
    );
}

// Export expanded insights for imports
export default ExpandedInsights;