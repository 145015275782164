// React
import React from 'react';

// Image
import gifLoading from '../images/loading.gif';

// CSS
import '../css/Loading.css';

// Loading image for the map
function Loading() {

    // Render the title
    return (
        <div className='loading-container'>
            <img src={ gifLoading } alt='Loading' className='loading-img' />
        </div>
    );
}

// Export loading for imports
export default Loading;