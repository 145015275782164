// React
import React from 'react';

// Bootstrap
import Button from 'react-bootstrap/Button';

// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// CSS
import '../css/Statistics.css';

// Statistics about the selected area
function Percentages(props) {
    var hesitant = props.hesitant;
    const toggle = props.toggle;
    const click = props.click;
    const showPop = props.showPop;

    // Share image
    var shareImg = (
        <svg width="100%" height="100%" viewBox="0 0 31 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id='share-pic' d="M10.8028 12.9091C11.3551 12.9091 11.8028 12.4614 11.8028 11.9091C11.8028 11.3568 11.3551 10.9091 10.8028 10.9091V12.9091ZM19.7887 10.9091C19.2364 10.9091 18.7887 11.3568 18.7887 11.9091C18.7887 12.4614 19.2364 12.9091 19.7887 12.9091V10.9091ZM16.0029 0.292893C15.6124 -0.0976311 14.9792 -0.0976311 14.5887 0.292893L8.22471 6.65685C7.83418 7.04738 7.83418 7.68054 8.22471 8.07107C8.61523 8.46159 9.2484 8.46159 9.63892 8.07107L15.2958 2.41421L20.9526 8.07107C21.3432 8.46159 21.9763 8.46159 22.3668 8.07107C22.7574 7.68054 22.7574 7.04738 22.3668 6.65685L16.0029 0.292893ZM10.8028 10.9091H3V12.9091H10.8028V10.9091ZM0 13.9091V39H2V13.9091H0ZM3 42H28V40H3V42ZM31 39V13.9091H29V39H31ZM28 10.9091H19.7887V12.9091H28V10.9091ZM16.2958 26.0505V1H14.2958V26.0505H16.2958ZM31 13.9091C31 12.2522 29.6569 10.9091 28 10.9091V12.9091C28.5523 12.9091 29 13.3568 29 13.9091H31ZM28 42C29.6569 42 31 40.6569 31 39H29C29 39.5523 28.5523 40 28 40V42ZM0 39C0 40.6568 1.34314 42 3 42V40C2.44772 40 2 39.5523 2 39H0ZM3 10.9091C1.34314 10.9091 0 12.2522 0 13.9091H2C2 13.3568 2.44771 12.9091 3 12.9091V10.9091Z" fill="black"/>
        </svg>
    );

    // Only get the variables if data is available
    var hesitantPerc = 0;
    var vaccinatedPerc = 0;
    var resistantPerc = 0;
    var readyPerc = 0;
    if (hesitant) {

        // If the toggle is county and we have the click, get the index of the county
        if ((toggle === 1) && (click.nameCounty)) {
            var search = click.fipsState + click.fipsCounty;
            var position = hesitant.geo.indexOf(search);

            // Select only that county
            hesitant = {
                population: [hesitant.population[position]],
                vaccinated_population: [hesitant.vaccinated_population[position]],
                low_hesitant_population: [hesitant.low_hesitant_population[position]],
                med_hesitant_population: [hesitant.med_hesitant_population[position]],
                high_hesitant_population: [hesitant.high_hesitant_population[position]],
                geo_name: [hesitant.geo_name[position]],
            };
        };

        // Calculate how many people we need to reach herd immunity threshold
        var totalPop = hesitant.population.reduce((a, b) => a + b, 0);

        // Find out the hesitant, resistant population percentage
        var hesitantPop = hesitant.med_hesitant_population.reduce((a, b) => a + b, 0);
        hesitantPerc = hesitantPop / totalPop;
        var resistantPop = hesitant.high_hesitant_population.reduce((a, b) => a + b, 0);
        resistantPerc = resistantPop / totalPop;
        var readyPop = hesitant.low_hesitant_population.reduce((a, b) => a + b, 0);
        readyPerc = readyPop / totalPop;
        vaccinatedPerc = 1 - hesitantPerc - resistantPerc - readyPerc;
    };

    // Render the statistics
    return (
        <Row>
            <Col className='col-6 col-md-12'>
                <h4 className='mt-2 mb-0'>
                    <span className='d-none d-md-block'>
                        Resistant: { Math.round(resistantPerc * 100) }%
                    </span>
                    <span className='d-block d-md-none'>
                        Resistant<br />{ Math.round(resistantPerc * 100) }%
                    </span>
                </h4>
            </Col>
            <Col className='col-6 col-md-12'>
                <h4 className='mt-2 mb-0'>
                    <span className='d-none d-md-block'>
                        Hesitant: { Math.round(hesitantPerc * 100) }%
                    </span>
                    <span className='d-block d-md-none'>
                        Hesitant<br />{ Math.round(hesitantPerc * 100) }%
                    </span>
                </h4>
            </Col>
            <Col className='col-6 col-md-12'>
                <h4 className='mt-2 mb-0'>
                    <span className='d-none d-md-block'>
                        Ready: { Math.round(readyPerc * 100) }%
                    </span>
                    <span className='d-block d-md-none'>
                        Ready<br />{ Math.round(readyPerc * 100) }%
                    </span>
                </h4>
            </Col>
            <Col className='col-6 col-md-12'>
                <h4 className='mt-2 mb-2'>
                    <span className='d-none d-md-block'>
                        Vaccinated: { Math.round(vaccinatedPerc * 100) }%
                    </span>
                    <span className='d-block d-md-none'>
                        Vaccinated<br />{ Math.round(vaccinatedPerc * 100) }%
                    </span>
                </h4>
            </Col>
            <Col className='col-12 col-md-12'>
            <p className='d-none d-md-block subtitle-text mt-2 mb-0 mb-md-2'>
                <i>
                    "Vaccinated" includes people who are partially vaccinated.
                </i>
            </p>
            </Col>
            <Col className='col-6 col-md-12'>
                <Button 
                        className='share-top-btn orange-btn mt-4 mb-0 d-none d-md-block'
                        onClick={ (e) => showPop(true) }
                    >
                    <div className='d-flex flex-direction-row align-items-center justify-content-center'>
                            <div className='share-icon'>{ shareImg }</div>
                            <p className='text-left'>
                                Share Data
                            </p>
                        </div>
                </Button>
            </Col>
        </Row>
    );
}

// Export percentages for imports
export default Percentages;