// React
import React from 'react';

// Bootstrap
import Table from 'react-bootstrap/Table';

// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Import components
import Navigation from './Navigation.js';
import Footer from './Footer.js';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

// Import CSS
import '../css/Home.css';

// Methodology is static
function Methodology() {
    
    // Return the JSX for the homepage
    return (
        <div>

            {/* Navigation echoes homepage */}
            <Navigation/>

            {/* Page body */}
            <div className='page'>

                    {/* Text */}
                    <Row className='mb-4 align-items-center'>
                        <Col md={ 6 } className='text-center text-md-left'>
                            <h1 className='page-title'>Methodology</h1>
                        </Col>
                        <Col md={ 6 } className='text-center text-md-right'>
                            <p className='mb-0'>
                                <a href='/' className='link-font'>
                                    Return to dashboard <FontAwesomeIcon icon={ faArrowRight } />
                                </a>
                            </p>
                        </Col>
                    </Row>

                    <div className='text-left'>
                        <p>
                            Over 8,000 survey responses were collected in two waves between July 27 and September 2, 2021, 
                            asking respondents a panel of questions about vaccine hesitancy. 
                            Demographic and geographic information were also collected from each respondent.
                            To collect a representative sample, surveys were conducted
                            using a number of modes, including live callers to cell phones and landlines,
                            text-to-web messaging, and online survey panels.
                        </p>

                        <p>
                            Demographic and geographic characteristics were combined
                            with 2019 ACS 5-year population estimates, CDC vaccination and COVID-19 data,
                            and 2020 election data to perform a multilevel regression and poststratification (MRP)
                            and generate population estimates specific to each demographic
                            (gender, age, race) and geographic level (county, state).
                            MRP is an established method for utilizing nationally representative polling
                            to generate geographic and sub-population estimates
                            and consistently outperforms disaggregation.
                        </p>

                        <p>
                            The margin of error (MOE) of the modeled data 
                            varies based on the granularity of the outputs, 
                            with national estimates for the entire US adult population 
                            having the lowest MOE and county-level, 
                            demographic hesitancy subgroup estimates having the largest MOE. 
                            As more survey data is collected, 
                            the MOEs in the following table will decrease.
                        </p>

                        <p>
                            For more information on the data or to report any issues, 
                            please contact <a href="mailto:vaccine@intrvl.us">vaccine@intrvl.us</a>.
                        </p>
                    </div>

                    <div className='w-100 text-left mb-5'>
                        <h3 className='mt-4 mb-4'>
                            Research & Documents
                        </h3>
                        <p className='text-left'>
                            <a href='/INTRVL-Vaccine-Research-September.pdf' target="_blank" rel="noreferrer">
                                Differentiating Vaccine Resistance and Hesitancy Update - 09.16.21
                            </a>
                        </p>
                        <p className='text-left'>
                            <a href='/Differentiating-Vaccine-Resistance-and-Hesitancy.pdf' target="_blank" rel="noreferrer">
                                Differentiating Vaccine Resistance and Hesitancy - 08.24.21
                            </a>
                        </p>
                        <p className='text-left'>
                            <a href='https://www.newswire.com/news/new-national-polling-shows-one-third-of-unvaccinated-adults-could-be-21482256?_ga=2.44874340.1772346204.1629859431-1739507696.1629859431' target="_blank" rel="noreferrer">
                                Joint Up to Us & INTRVL Press Release - 08.25.21
                            </a>
                        </p>
                    </div>

                    <div className='w-100 text-left mt-4 mb-4'>
                        <h3>
                            Margin of Error
                        </h3>
                    </div>

                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th colSpan='1'></th>
                                <th colSpan='1'>Approximate N</th>
                                <th colSpan='2' rowSpan='1'>5,000</th>
                            </tr>
                            <tr>
                                <th />
                                <th />
                                <th colSpan='1'>80% MOEs</th>
                                <th colSpan='1'>95% MOEs</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan='1' rowSpan='3'>National Topline</td>
                                <td>Vaccination Rate</td>
                                <td>1.3%</td>
                                <td>1.7%</td>
                            </tr>
                            <tr>
                                <td>Unvaccinated Hesitancy Level</td>
                                <td>2.1%</td>
                                <td>3.0%</td>
                            </tr>
                            <tr>
                                <td>Hesitancy Level Concern</td>
                                <td>2.8%</td>
                                <td>4.3%</td>
                            </tr>
                            <tr>
                                <td colSpan='1' rowSpan='3'>Average State</td>
                                <td>Vaccination Rate</td>
                                <td>5.0%</td>
                                <td>7.5%</td>
                            </tr>
                            <tr>
                                <td>Unvaccinated Hesitancy Level</td>
                                <td>6.5%</td>
                                <td>9.8%</td>
                            </tr>
                            <tr>
                                <td>Hesitancy Level Concern</td>
                                <td>9.1%</td>
                                <td>13.3%</td>
                            </tr>
                            <tr>
                                <td colSpan='1' rowSpan='3'>National Demo (White Subgroups)</td>
                                <td>Vaccination Rate</td>
                                <td>2.6%</td>
                                <td>4.0%</td>
                            </tr>
                            <tr>
                                <td>Unvaccinated Hesitancy Level</td>
                                <td>4.9%</td>
                                <td>7.5%</td>
                            </tr>
                            <tr>
                                <td>Hesitancy Level Concern</td>
                                <td>7.3%</td>
                                <td>11.0%</td>
                            </tr>
                            <tr>
                                <td colSpan='1' rowSpan='3'>National Demo (Black / Hispanic Subgroups)</td>
                                <td>Vaccination Rate</td>
                                <td>4.8%</td>
                                <td>7.0%</td>
                            </tr>
                            <tr>
                                <td>Unvaccinated Hesitancy Level</td>
                                <td>8.4%</td>
                                <td>12.3%</td>
                            </tr>
                            <tr>
                                <td>Hesitancy Level Concern</td>
                                <td>12.0%</td>
                                <td>17.8%</td>
                            </tr>
                            <tr>
                                <td colSpan='1' rowSpan='3'>Average County Demo (White Subgroups)</td>
                                <td>Vaccination Rate</td>
                                <td>10.0%</td>
                                <td>14.5%</td>
                            </tr>
                            <tr>
                                <td>Unvaccinated Hesitancy Level</td>
                                <td>13.7%</td>
                                <td>19.6%</td>
                            </tr>
                            <tr>
                                <td>Hesitancy Level Concern</td>
                                <td>19.2%</td>
                                <td>27.1%</td>
                            </tr>
                            <tr>
                                <td colSpan='1' rowSpan='3'>Average County Demo (Black / Hispanic Subgroups)</td>
                                <td>Vaccination Rate</td>
                                <td>10.9%</td>
                                <td>16.0%</td>
                            </tr>
                            <tr>
                                <td>Unvaccinated Hesitancy Level</td>
                                <td>15.8%</td>
                                <td>22.4%</td>
                            </tr>
                            <tr>
                                <td>Hesitancy Level Concern</td>
                                <td>20.8%</td>
                                <td>29.2%</td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className='w-100 text-left mb-5'>
                        <h3 className='mt-4 mb-4'>
                            Additional Resources
                        </h3>
                        <p className='text-left'>
                            <a href='https://www150.statcan.gc.ca/n1/en/catalogue/12-001-X19970023616'>
                                Postratification into Many Categories Using Hierarchical Logistic Regression
                                - Andrew Gelman & Thomas Little
                            </a>
                        </p>
                        <p className='text-left'>
                            <a href='http://www.columbia.edu/~jhp2121/publications/HowShouldWeEstimateOpinion.pdf'>
                                How Should We Estimate Public Opinion in the States?
                                – Jeffrey R. Lax & Justin H. Phillips
                            </a>
                        </p>
                        <p className='text-left'>
                            <a href='https://www.cambridge.org/core/journals/political-analysis/article/abs/bayesian-multilevel-estimation-with-poststratification-statelevel-estimates-from-national-polls/22A5EF78D027E76C782B3280D400FCC9'>
                                Bayesian Multilevel Estimation with Poststratification: State-Level Estimates from National Polls
                                – David K. Park, Andrew Gelman & Joseph Bafumi
                            </a>
                        </p>
                    </div>

            {/* Close out the container */}
            </div>

            {/* Footer at the bottom */}
            <Footer/>

        {/* Close out to return */}
        </div>
    )
}

// Export methodology for imports
export default Methodology;
