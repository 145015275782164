// Functions that the components may share
const stateObj = {
    "AL": "Alabama",
    "AK": "Alaska",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "FL": "Florida",
    "GA": "Georgia",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PA": "Pennsylvania",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming"
};

// Map FIPS to state
const fipsObj = {
    '01': 'AL', 
    '02': 'AK', 
    '04': 'AZ', 
    '05': 'AR', 
    '06': 'CA', 
    '08': 'CO', 
    '09': 'CT', 
    '10': 'DE', 
    '11': 'DC', 
    '12': 'FL', 
    '13': 'GA', 
    '15': 'HI', 
    '16': 'ID', 
    '17': 'IL', 
    '18': 'IN', 
    '19': 'IA', 
    '20': 'KS', 
    '21': 'KY', 
    '22': 'LA', 
    '23': 'ME', 
    '24': 'MD', 
    '25': 'MA', 
    '26': 'MI', 
    '27': 'MN', 
    '28': 'MS', 
    '29': 'MO', 
    '30': 'MT', 
    '31': 'NE', 
    '32': 'NV', 
    '33': 'NH', 
    '34': 'NJ', 
    '35': 'NM', 
    '36': 'NY', 
    '37': 'NC', 
    '38': 'ND', 
    '39': 'OH', 
    '40': 'OK', 
    '41': 'OR', 
    '42': 'PA', 
    '44': 'RI', 
    '45': 'SC', 
    '46': 'SD', 
    '47': 'TN', 
    '48': 'TX', 
    '49': 'UT', 
    '50': 'VT', 
    '51': 'VA', 
    '53': 'WA', 
    '54': 'WV', 
    '55': 'WI', 
    '56': 'WY'
 }

// Use the common functions throughout the components
const Common = {

    // Herd immunity threshold
    getHerdThreshold: function() {
        return 0.7;
    },

    // Return the image options
    imageOptions: function() {
        return {
            scale: 1,
            encoderOptions: 1,
            backgroundColor: 'rgb(255, 255, 255)' ,
            fonts: [
                {
                  url: 'https://anima-uploads.s3.amazonaws.com/projects/6125962c1a2309045dfc5625/fonts/monumentextended-regular.otf',
                  format: 'font/otf',
                  text: "@font-face { font-family: 'MonumentExtended-Regular'; src: url(https://anima-uploads.s3.amazonaws.com/projects/6125336a2b449a043fcc69fa/fonts/monumentextended-regular.otf);}",
                },
                {
                    url: 'https://anima-uploads.s3.amazonaws.com/projects/60bd0a117685c295116596b1/fonts/silkamono-regular.otf',
                    format: 'font/otf',
                    text: "@font-face { font-family: 'SilkaMono-Regular'; src: url(https://anima-uploads.s3.amazonaws.com/projects/60bd0a117685c295116596b1/fonts/silkamono-regular.otf);}",
                  },
            ]
        };
    },

    // Return the list of states
    getStateArray: function() {
        return Object.keys(stateObj);
    },

    // Convert an abbreviation of a state to the full name
    getStateName: function(abbreviation) {
        return stateObj[abbreviation];
    },

    // Convert FIPS code to state abbreviation
    getStateAbbr: function(fips) {
        return fipsObj[fips];
    },

    // Take the filters to create the demographic title
    makeDemographicText: function(filters, geo=true) {
        var demoArray = [];

        // Add age to the array
        if (filters.age_groups !== 'all') {
            demoArray.push(
                filters.age_groups + " Year-Old"
            );
        };

        // Add race to the array
        if (filters.race !== 'all') {
            demoArray.push(
                filters.race.replace(
                    /\w\S*/g, function(txt) {
                        var first = txt.charAt(0).toUpperCase();
                        var second = txt.substr(1).toLowerCase();
                        return first + second;
                    }
                )
            );
        };

        // Join the array with commas
        var demoText = '' + demoArray.join(', ');
        demoArray = [demoText];

        // Add sex if applicable
        if (filters.gender !== 'all') {
            if (filters.gender === 'male') {
                demoArray.push(
                    'Men'
                );
            } else {
                demoArray.push(
                    'Women'
                );
            };
        } else {
            demoArray.push(
                'Adults'
            );
        };

        // If there is no state, use United States
        if (geo) {
            if (filters.state === 'all') {
                demoArray.push(
                    'in the United States'
                );
            } else if (filters.state.includes(',')) {
                demoArray.push(
                    'in ' + filters.state
                );
            } else {
                demoArray.push(
                    'in ' + stateObj[filters.state]
                );
            };
        };

        // Join the array with spaces
        demoText = demoArray.join(' ');

        // Return the demographic text
        return demoText;
    },

    // Take the filters to create the geographic title
    makeGeoText: function(filters) {
        var geoArray = [];

        // If there is no state, use United States
        if (filters.state === 'all') {
            geoArray.push(
                'in the United States'
            );
        } else if (filters.state.includes(',')) {
            geoArray.push(
                'in ' + filters.state
            );
        } else {
            geoArray.push(
                'in ' + stateObj[filters.state]
            );
        };

        // Join the array with spaces
        var geoText = geoArray.join(' ');

        // Return the demographic text
        return geoText;
    }
};

// Export the common functions, used in other modules
export default Common;