// React
import React from 'react';

// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// SVG
import { SvgLoader, SvgProxy } from 'react-svgmt';

// Icons
import People from '../images/people.svg';
import Concerns from '../images/concerns.svg';
import Graph from '../images/graph.svg';

// Images
import Bar from '../images/bar.svg';

// Components
import Common from './Common.js';

// CSS
import '../css/Statistics.css';

// Statistics about the selected area
function Statistics(props) {
    var hesitant = props.hesitant;
    var stateReasons = props.stateReasons;
    var countyReasons = props.countyReasons;
    const toggle = props.toggle;
    const click = props.click;

    // Return loading section if this data is unavailable
    // if ((!stateReasons) && (!countyReasons) && (!hesitant)) {
    //     return (
    //         <div className='statistics-box d-flex align-items-center'>
    //             <Loading />
    //         </div>
    //     );
    // };

    // Only get the variables if data is available
    var totalPop = 0;
    var neededPop = 0;
    var hundredPop = 0;
    var hesitantPerc = 0;
    var vaccinatedPerc = 0;
    var resistantPerc = 0;
    var readyPerc = 0;
    if (hesitant) {

        // If the toggle is county and we have the click, get the index of the county
        if ((toggle === 1) && (click.nameCounty)) {
            var search = click.fipsState + click.fipsCounty;
            var position = hesitant.geo.indexOf(search);

            // Select only that county
            hesitant = {
                population: [hesitant.population[position]],
                vaccinated_population: [hesitant.vaccinated_population[position]],
                low_hesitant_population: [hesitant.low_hesitant_population[position]],
                med_hesitant_population: [hesitant.med_hesitant_population[position]],
                high_hesitant_population: [hesitant.high_hesitant_population[position]],
                geo_name: [hesitant.geo_name[position]],
            };
        };

        // Calculate how many people we need to reach herd immunity threshold
        totalPop = hesitant.population.reduce((a, b) => a + b, 0);
        var thresholdPop = Common.getHerdThreshold() * totalPop;

        // Correct the population figure formatting
        if (neededPop < 0) {
            neededPop = 0;
        };

        // Find out the hesitant, resistant population percentage
        var hesitantPop = hesitant.med_hesitant_population.reduce((a, b) => a + b, 0);
        hesitantPerc = hesitantPop / totalPop;
        var resistantPop = hesitant.high_hesitant_population.reduce((a, b) => a + b, 0);
        resistantPerc = resistantPop / totalPop;
        var readyPop = hesitant.low_hesitant_population.reduce((a, b) => a + b, 0);
        readyPerc = readyPop / totalPop;
        vaccinatedPerc = 1 - hesitantPerc - resistantPerc;

        // Use the vaccinated percentage to get our threshold
        var vaccinatedPop = vaccinatedPerc * totalPop;
        neededPop = thresholdPop - vaccinatedPop;
        hundredPop = totalPop - vaccinatedPop;
    };

    // Sort the reasons by their values
    var reasonsText = [];
    var blankText = [
        <p className='mb-0' key={ 1 }>
            1.
        </p>,
        <p className='mb-0' key={ 2 }>
            2.
        </p>,
        <p className='mb-0' key={ 3 }>
            3.
        </p>,
        <p className='mb-0' key={ 4 }>
            4.
        </p>,
        <p className='mb-0' key={ 5 }>
            5.
        </p>
    ];
    var reasonsAmt = [];
    if ((stateReasons) && (countyReasons)) {

        // If the toggle is county and we have the click, get the index of the county
        if ((toggle === 1) && (click.nameCounty)) {
            search = click.fipsState + click.fipsCounty;
            var reasons = countyReasons[search];
        } else {
            reasons = stateReasons;
        };

        var reasonsArray = Object.entries(reasons);
        var reasonsSorted = reasonsArray.sort((a, b) => b[1] - a[1]);

        // Iterate to build the text and amounts
        for (var i = 0; i < 5; i++) {
            var text = reasonsSorted[i][0];
            var amt = reasonsSorted[i][1];

            // Pop the text into the array
            reasonsText.push(
                <p className='mb-0' key={ i }>
                    { i + 1 }. { text }
                </p>
            );

            // Pop the bar into the array
            reasonsAmt.push(
                <p className='mb-0' key={ i }>
                    <SvgLoader path={ Bar }>
                        <SvgProxy 
                            selector="#reasons-rect" 
                            width={ (amt * 100).toFixed(0) } 
                        />
                    </SvgLoader>
                </p>
            )
        };
    };

    // Round the population needed to reach herd
    if (neededPop > 0) {
        if (neededPop > 1000000) {
            neededPop = Math.round(neededPop / 1000000) * 1000000;
        } else if (neededPop > 10000) {
            neededPop = Math.round(neededPop / 10000) * 10000;
        } else if (neededPop > 100) {
            neededPop = Math.round(neededPop / 100) * 100;
        } else if (neededPop > 0) {
            neededPop = 100;
        };
    };

    // Round the population needed to reach 100%
    if (neededPop <= 0) {
        if (hundredPop > 1000000) {
            hundredPop = Math.round(hundredPop / 1000000) * 1000000;
        } else if (hundredPop > 10000) {
            hundredPop = Math.round(hundredPop / 10000) * 10000;
        } else if (hundredPop > 100) {
            hundredPop = Math.round(hundredPop / 100) * 100;
        } else if (hundredPop > 0) {
            hundredPop = 100;
        };
    };

    // Round the population needed to reach 100%
    if (totalPop) {
        if (totalPop > 1000000) {
            totalPop = Math.round(totalPop / 1000000) * 1000000;
        } else if (totalPop > 10000) {
            totalPop = Math.round(totalPop / 10000) * 10000;
        } else if (totalPop > 100) {
            totalPop = Math.round(totalPop / 100) * 100;
        } else if (totalPop > 0) {
            totalPop = 100;
        };
    };

    // Render the statistics
    return (
        <Row className='mt-5 mt-md-2 mb-0 mb-md-4'>
            <Col md={ 3 } className='statistics-box text-center mb-0 mb-md-2 order-2 order-md-1'>
                <img src={ People } alt='People' className='statistics-icon'></img>
                <h3 className='page-title statistics-title mt-4 mb-4'>
                    { (neededPop > 0) 
                        ? Math.round(neededPop).toLocaleString() 
                        : Math.round(hundredPop).toLocaleString() 
                    }
                </h3>
                <p className='mb-5 mb-md-0'>
                    { (neededPop > 0)
                        ? "vaccinations needed to reach this population's " +  Math.round(Common.getHerdThreshold() * 100) + '% vaccination goal.'
                        : "vaccinations needed to reach a 100% vaccination rate for this population."
                    }
                </p>
            </Col>
            <Col md={ 6 } className='statistics-box text-center mb-5 mb-md-2 order-1 order-md-2'>
                <img src={ Concerns } alt='Concerns' className='statistics-icon'></img>
                <h3 className='page-title statistics-title mt-4 mb-4'>
                    Top Concerns
                </h3>
                <Row>
                    <Col md={ 6 } className='text-center text-md-left mb-0'>
                        { (reasonsText.length > 0) ? reasonsText : blankText }
                    </Col>
                    <Col md={ 6 } className='text-right d-none d-md-block'>
                        { reasonsAmt }
                        <p className='subtitle-text mt-1 text-right'>
                            <i>est. size of concerned population</i>
                        </p>
                    </Col>
                </Row>
            </Col>
            <Col md={ 3 } className='d-none d-md-block statistics-box text-center mb-0 mb-md-2 order-3'>
                <img src={ Graph } alt='Population' className='statistics-icon'></img>
                <h3 className='page-title statistics-title mt-4 mb-4'>
                    Population
                </h3>
                <p className='mb-0'>
                    <b>{ totalPop.toLocaleString() }</b>
                </p>
                <br />
                <Row>
                    <Col md={ 6 } className='text-center text-md-left mb-3 mb-md-0'>
                        <p className='mb-0'>
                            Resistant<span className='d-block d-md-none'>{ Math.round(resistantPerc * 100) }%</span>
                        </p>
                        <p className='mb-0'>
                            Hesitant<span className='d-block d-md-none'>{ Math.round(hesitantPerc * 100) }%</span>
                        </p>
                        <p className='mb-0'>
                            Ready<span className='d-block d-md-none'>{ Math.round(readyPerc * 100) }%</span>
                        </p>
                    </Col>
                    <Col md={ 6 } className='text-right d-none d-md-block'>
                        <p className='mb-0'>
                            <b>{ Math.round(resistantPerc * 100) }%</b>
                        </p>
                        <p className='mb-0'>
                            <b>{  Math.round(hesitantPerc * 100) }%</b>
                        </p>
                        <p className='mb-0'>
                            <b>{ Math.round(readyPerc * 100) }%</b>
                        </p>
                    </Col>
                </Row>            
            </Col>
        </Row>
    );
}

// Export statistics for imports
export default Statistics;